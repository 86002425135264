import axiosInstance from '../axiosInstance';
import BaseService from './baseService';

class AdvertisementsService extends BaseService<AdvertisementInterface> {
	constructor() {
		super('advertisement', 'advertisements');
	}

	async addClick(advertisementID: number): Promise<void> {
		await axiosInstance.post(`/advertisements/${advertisementID}/click`);
	}

	async addView(advertisementID: number): Promise<void> {
		await axiosInstance.post(`/advertisements/${advertisementID}/view`);
	}
}

// This type of exporting is intended to make sure service acts as a singleton.
export default new AdvertisementsService();
