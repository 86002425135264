import { Modal, Button, Form } from 'react-bootstrap';
import React, { useCallback, useEffect, } from 'react';
import { useForm } from 'react-hook-form';
import { useUser } from '@src/context/UserContext';
import { toast } from 'react-toastify';
import { towns } from '@src/assets/FinnishTowns';
import advertisementsService from '@src/api/services/advertisementsService';
import { getErrorString } from '@src/utils/getErrorString';
import { useDropzone } from 'react-dropzone';

const SUPPORTED_FILE_TYPES = ['.jpeg', '.jpg', '.png', '.webp'];
const MAXIMUM_FILE_SIZE = 50 * 1024 * 1024; // 50 MB

type PropTypes = {
	editingAdvertisement: AdvertisementInterface | null | undefined;
	setEditingAdvertisement: React.Dispatch<React.SetStateAction<AdvertisementInterface | null | undefined>>;
	fetchAdvertisements: () => void;
};

const AdvertisementForm = (props: PropTypes): JSX.Element | null => {
	const { editingAdvertisement, setEditingAdvertisement, fetchAdvertisements } = props;
	const advertisement = editingAdvertisement;
	const advertisementID = advertisement?.advertisementID;
	const [isRemoving, setIsRemoving] = React.useState<boolean>(false);
	const { user } = useUser();

	const {
		reset,
		setValue,
		handleSubmit,
		register,
		watch,
		clearErrors,
		formState: { dirtyFields, },
	} = useForm({
		defaultValues: {
			...advertisement,
		},
	});
	const type = watch('type');

	useEffect(() => {
		reset({
			...advertisement,
		});
		return (): void => {
			reset();
		};
	}, [advertisement, reset]);


	const handleSave = async (data: Partial<AdvertisementInterface>): Promise<void> => {
		try {

			let payload: Partial<AdvertisementInterface> = {};



			if (advertisement?.advertisementID) {
				const dirtyKeys = Object.keys(dirtyFields);
				// update only dirty fields
				if (dirtyKeys.length) {
					for (const key of dirtyKeys) {
						payload[key] = data[key];
					}
				}

				payload['advertisementID'] = advertisement.advertisementID;
			} else {
				payload = {
					...data,
				};
				if (payload['type'] !== 'local') {
					delete payload['location'];
				}
			}

			payload = addimage(payload);

			if (advertisementID) {
				await advertisementsService.update(advertisementID, payload);
			} else {
				await advertisementsService.create(payload);
			}

			setEditingAdvertisement(undefined);
			fetchAdvertisements();
			toast.success('Advertisement saved successfully');
		} catch (error) {
			toast.error(getErrorString(error));
			console.error(error);
		}
	};

	const handleDelete = async (): Promise<void> => {
		try {
			if (advertisement?.advertisementID && user?.userID) {
				await advertisementsService.deleteSingleResource(advertisement.advertisementID);
			}
			setEditingAdvertisement(undefined);
			fetchAdvertisements();
			toast.success('Advertisement removed successfully');
		} catch (error) {
			console.error(error);
		}
	};

	function addimage(
		payload: Partial<AdvertisementInterface>,
	): Partial<AdvertisementInterface> {
		if (payload.image && payload.image.length > 0) {
			const image = payload.image[0];
			if (image.fileBase64 && image.fileName) {
				delete payload.image[0].fileName;
				delete payload.image[0].fileUrl;
				delete payload.image[0].orderNumber;
			}
		}

		return payload;
	}


	const getBase64 = useCallback(async (file: File): Promise<string> => {
		try {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			return new Promise<string>((resolve, reject) => {
				reader.onload = (): void => {
					if (typeof reader.result === 'string') {
						resolve(reader.result);
					} else {
						reject(new Error('Failed to read file as base64.'));
					}
				};
				reader.onerror = (error): void => {
					reject(error);
				};
			});
		} catch (error) {
			console.error(error);
			throw new Error('An error occurred while processing the file.');
		}
	}, []);

	const onDrop = useCallback(
		async (acceptedFiles: File[]): Promise<void> => {
			const base64 = await getBase64(acceptedFiles[0]);
			const fileName = acceptedFiles[0].name;
			setValue('image.0.fileBase64', base64, {
				shouldDirty: true,
			});
			setValue('image.0.fileName', fileName, {
				shouldDirty: true,
			});
			clearErrors('image');
		},
		[setValue, clearErrors, getBase64],
	);

	const onDropRejected = useCallback(
		(error: unknown): void => {
			let errorMessage = "Could not upload image";
			if (Array.isArray(error) && error.length && Array.isArray(error[0].errors)) {
				const firstError = error[0].errors[0];
				if (firstError.code === 'file-invalid-type') {
					errorMessage = `Invalid file type. Supported types are ${SUPPORTED_FILE_TYPES.join(
						', ',
					)}`;
				} else if (firstError.code === 'file-too-large') {
					errorMessage = `File to large. Max size is ${MAXIMUM_FILE_SIZE / (1024 * 1024)
						} MB`;
				} else {
					errorMessage = firstError.message;
				}
			}

			toast.error(errorMessage);
		},
		[],
	);

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		onDropRejected,
		maxSize: MAXIMUM_FILE_SIZE,
		accept: {
			'application/pdf': [],
			'image/jpeg': [],
			'image/jpg': [],
			'image/png': [],
			'image/webp': [],
		},
		multiple: false,
	});

	const currentFile =
		watch('image')?.[0]?.fileBase64 || watch('image')?.[0]?.fileUrl
			? watch('image')?.[0]
			: null;


	if (advertisement === undefined) return null;
	return (
		<>
			<Modal show={isRemoving} onHide={(): void => setIsRemoving(false)} centered>
				<Modal.Header closeButton>
					<Modal.Title>Remove advertisement</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<span>Are you sure you want to remove advertisement {advertisement?.advertiser}?</span>
				</Modal.Body>
				<Modal.Footer className="justify-content-start">
					<Button className="btn-pink" onClick={handleDelete}>
						Remove
					</Button>
					<Button variant="secondary" onClick={(): void => setIsRemoving(false)}>
						Cancel
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				show={advertisement !== undefined}
				onHide={(): void => setEditingAdvertisement(undefined)}
				backdrop="static"
				keyboard={false}
				size="lg"
				centered
				enforceFocus={false}
			>
				<Form onSubmit={handleSubmit(handleSave)}>
					<Modal.Header closeButton>
						<Modal.Title>{advertisement?.advertiser || 'New advertisement'}</Modal.Title>
					</Modal.Header>

					<Modal.Body className="d-flex flex-column gap-2">
						{advertisementID && (
							<Form.Group>
								<Form.Label>Analytics:</Form.Label>
								<div className="d-flex gap-2">
									<Form.Label>
										<span style={{ fontWeight: 'normal' }}>Views: </span>
										{advertisement?.views}
									</Form.Label>
									<Form.Label>
										<span style={{ fontWeight: 'normal' }}>Clicks: </span> {advertisement?.clicks}
									</Form.Label>
								</div>
							</Form.Group>
						)}
						<Form.Group>
							<Form.Switch id="isActive" {...register('isActive')} type="checkbox" label="Active" />
						</Form.Group>

						<Form.Group className="required">
							<Form.Label>Advertiser</Form.Label>
							<Form.Control maxLength={100} {...register('advertiser', { required: true })} />
						</Form.Group>
						<div className="d-flex flex-column gap-2">
							<Form.Label>Advertisement image</Form.Label>
							{currentFile && (
								<img src={currentFile.fileBase64 || currentFile.fileUrl} alt="Ad image" className="w-50" />
							)}
							<div className="d-flex justify-content-between gap-2">
								{currentFile && (
									<Button
										className="btn-secondary w-100"
										onClick={(): void => {
											setValue('image', []),
											{
												shouldDirty: true,
												shouldValidate: true,
											};
										}}
									>
										Delete image
									</Button>
								)}
								<div
									{...getRootProps()}
									style={{
										width: 'max-content',
									}}
									className="w-100"
								>
									<input {...getInputProps()} />
									<Button className="btn-pink w-100">Upload image</Button>
								</div>
							</div>

						</div>
						<Form.Group>
							<Form.Label>Redirect on click</Form.Label>
							<Form.Control maxLength={1000} {...register('redirectUrl')} />
						</Form.Group>
						<Form.Group className="required">
							<Form.Label>Type</Form.Label>
							<Form.Select
								{...register('type', {
									onChange: e => {
										if (e.value !== 'local') setValue('location', null, { shouldDirty: true });
									},
								})}
							>
								<option value="global">Global</option>
								<option value="local">Local</option>
								<option value="toast">Toast</option>
							</Form.Select>
						</Form.Group>
						<Form.Group className="required">
							<Form.Label>Location</Form.Label>
							<Form.Select
								disabled={type !== 'local'}
								{...register('location', { required: type === 'local' })}
							>
								{type !== 'local' && <option value={''}>Global</option>}
								{towns.map((town: string) => (
									<option key={town} value={town}>
										{town}
									</option>
								))}
							</Form.Select>
						</Form.Group>

						<Form.Group>
							<Form.Switch
								id="showOnlyToLoggedUsers"
								{...register('isShowOnlyToLoggedUsers')}
								type="checkbox"
								label="Show only to logged users"
							/>
						</Form.Group>
						<Form.Group>
							<Form.Switch
								id="showOnlyToAdultUsers"
								{...register('isShowOnlyToAdultUsers')}
								type="checkbox"
								label="Show only to adult users"
							/>
						</Form.Group>

					</Modal.Body>
					<Modal.Footer className="justify-content-between">
						<div className="d-flex gap-2">
							<Button className="btn-pink" type="submit">
								Save
							</Button>
							<Button variant="secondary" onClick={(): void => setEditingAdvertisement(undefined)}>
								Cancel
							</Button>
						</div>
						{advertisement?.advertisementID && (
							<Button variant="outline-danger" onClick={(): void => setIsRemoving(true)}>
								Delete
							</Button>
						)}
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};

export default AdvertisementForm;
