import { AxiosResponse } from 'axios';
import axiosInstance from '../axiosInstance';

export default abstract class BaseService<T> {
	protected crudResponseObjectName: string;
	protected crudResponseArrayName: string;

	constructor(crudResponseObjectName: string, crudResponseArrayName: string) {
		this.crudResponseObjectName = crudResponseObjectName;
		this.crudResponseArrayName = crudResponseArrayName;
	}

	async getAllResources(queryParams?: {
		[key: string]: string;
	}): Promise<T[]> {
		const response: AxiosResponse<{ [key: string]: T[] }> =
			await axiosInstance.get(`/${this.crudResponseArrayName}`, {
				params: {
					...queryParams,
				},
			});

		return response.data[this.crudResponseArrayName];
	}

	async getSingleResource(
		resourceID: number,
		queryParams?: { [key: string]: string },
	): Promise<T> {
		const response: AxiosResponse<{ [key: string]: T }> =
			await axiosInstance.get(
				`/${this.crudResponseArrayName}/${resourceID}`,
				{
					params: {
						...queryParams,
					},
				},
			);

		return response.data[this.crudResponseObjectName];
	}

	async deleteSingleResource(resourceID: number): Promise<void> {
		await axiosInstance.delete(
			`/${this.crudResponseArrayName}/${resourceID}`,
		);
	}

	async create(data: Partial<T>): Promise<T> {
		const response: AxiosResponse<{ [key: string]: T }> =
			await axiosInstance.post(`/${this.crudResponseArrayName}`, data);

		return response.data[this.crudResponseObjectName];
	}

	async update(resourceID: number, data: Partial<T>): Promise<T> {
		const response: AxiosResponse<{ [key: string]: T }> =
			await axiosInstance.patch(
				`/${this.crudResponseArrayName}/${resourceID}`,
				data,
			);

		return response.data[this.crudResponseObjectName];
	}

	async delete(resourceID: number): Promise<void> {
		await axiosInstance.delete(
			`/${this.crudResponseArrayName}/${resourceID}`,
		);
	}
}
