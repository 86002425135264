import axiosInstance from '../axiosInstance';
import BaseService from './baseService';

class OrganizationsService extends BaseService<OrganizationInterface> {
	constructor() {
		super('organization', 'organizations');
	}
}

// This type of exporting is intended to make sure service acts as a singleton.
export default new OrganizationsService();
