import { AxiosResponse } from 'axios';
import axiosInstance from '../axiosInstance';
import BaseService from './baseService';

type Meta = {
	events: {
		count: number;
	};
	tickets: {
		count: number;
	};
	users: {
		count: number;
	};
	organizations: {
		count: number;
	};
};

class MetaService extends BaseService<Meta> {
	constructor() {
		super('meta', 'meta');
	}

	async getSuperAdminMeta(): Promise<AxiosResponse<Meta>> {
		return (await axiosInstance.get('meta')).data;
	}
}

// This type of exporting is intended to make sure service acts as a singleton.
export default new MetaService();
