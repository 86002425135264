import axiosInstance from '../axiosInstance';
import BaseService from './baseService';

class FilesService extends BaseService<FileInterface> {
	constructor() {
		super('file', 'files');
	}
}

// This type of exporting is intended to make sure service acts as a singleton.
export default new FilesService();
