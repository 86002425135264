import axiosInstance from '../axiosInstance';
import BaseService from './baseService';

class EventsService extends BaseService<EventInterface> {
	constructor() {
		super('event', 'events');
	}

	getOrganizationEvents = async (
		organizationID: number,
	): Promise<EventInterface[]> => {
		const response = await axiosInstance.get(
			`organizations/${organizationID}/events`,
		);
		return response.data[this.crudResponseArrayName];
	};
}

// This type of exporting is intended to make sure service acts as a singleton.
export default new EventsService();
