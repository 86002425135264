import { AxiosResponse } from 'axios';
import axiosInstance from '../axiosInstance';
import BaseService from './baseService';

class TicketsService extends BaseService<TicketInterface> {
	constructor() {
		super('ticket', 'tickets');
	}

	async getAllByEventID(eventID: number): Promise<TicketInterface[]> {
		const response: AxiosResponse<{ [key: string]: TicketInterface[] }> =
			await axiosInstance.get(`events/${eventID}/tickets`);
		return response.data[this.crudResponseArrayName];
	}

	async getAllByUserID(userID: number): Promise<TicketInterface[]> {
		const response: AxiosResponse<{ [key: string]: TicketInterface[] }> =
			await axiosInstance.get(`users/${userID}/tickets`);
		return response.data[this.crudResponseArrayName];
	}
	async getAllByOrganizationID(
		organizationID: number,
	): Promise<TicketInterface[]> {
		const response: AxiosResponse<{ [key: string]: TicketInterface[] }> =
			await axiosInstance.get(`organizations/${organizationID}/tickets`);
		return response.data[this.crudResponseArrayName];
	}
}

// This type of exporting is intended to make sure service acts as a singleton.
export default new TicketsService();
