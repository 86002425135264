import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

type AxiosRequestConfigOmitted = Omit<AxiosRequestConfig, 'headers'>;
const TOKEN_NAME = 'ticketlessAdminAuthToken';

const config: AxiosRequestConfigOmitted = {
	baseURL: API_BASE_URL,
	timeout: 60000,
};

type AxiosInstanceOmitted = Omit<AxiosInstance, 'config'> &
	Omit<AxiosInstance, 'interceptors'>;

type CustomAxiosInstance = AxiosInstanceOmitted & {
	config?: AxiosRequestConfigOmitted;
};

type axiosOmitted = Omit<typeof axios, 'create'>;

type CustomAxios = axiosOmitted & {
	create: (config: AxiosRequestConfigOmitted) => CustomAxiosInstance;
};

const customAxios = axios as CustomAxios;
const axiosInstance: CustomAxiosInstance = customAxios.create(config);

axiosInstance.interceptors.request.use(config => {
	const token = localStorage.getItem(TOKEN_NAME);
	if (token) {
		config.headers.Authorization = `Bearer ${token}`;
	}

	config.headers['Accept-Language'] = 'fi';
	return config;
});

axiosInstance.interceptors.response.use(
	response => response,
	error => {
		if (error.response) {
			if (error.response.data) {
				if (error.response.data.error) {
					if (error.response.data.error.code === 401001) {
						localStorage.removeItem(TOKEN_NAME);
					}
					throw error.response.data.error;
				}

				throw error.response.data;
			}
		}

		/**
		 * Didn't receive response from server -> probably offline
		 */
		if (error.request) {
			const offlineError = {
				code: 9999,
				title: 'Error connecting to server',
				details: '',
			};

			// eslint-disable-next-line @typescript-eslint/only-throw-error
			throw offlineError;
		}
	},
);
export default axiosInstance;
