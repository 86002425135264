export const getErrorString = (error: unknown): string => {
	if (
		typeof error === 'object' &&
		error !== null &&
		'title' in error &&
		'details' in error
	) {
		const { title, details } = error;

		if (typeof details === 'string' && details !== '') {
			return details;
		}

		return typeof title === 'string' ? title : 'Unknown error';
	}

	if (typeof error === 'string') {
		return error;
	} else if (
		typeof error === 'object' &&
		error !== null &&
		'details' in error
	) {
		let completeError = '';

		if ('title' in error && typeof error.title === 'string') {
			completeError += error.title;
		}

		if (typeof error.details === 'string') {
			if (completeError.length > 0) {
				completeError += ' - ';
			}
			completeError += error.details;
		}

		return completeError;
	} else if (
		error &&
		typeof error === 'object' &&
		Object.keys(error).length > 0
	) {
		return Object.values(error as object)[0] as string;
	} else {
		return 'Something went wrong, please try again later';
	}
};
