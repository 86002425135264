import { AxiosResponse } from 'axios';
import axiosInstance from '../axiosInstance';

type LoginInputs = {
	email: string;
	password: string;
};

type LoginResponse = {
	user: UserInterface;
	token: string;
};

export default class AuthService {
	static async authenticate(): Promise<{ user: UserInterface }> {
		const response: AxiosResponse<{ user: UserInterface }> =
			await axiosInstance.post('/authenticate');
		return response.data;
	}

	static async login({
		email,
		password,
	}: LoginInputs): Promise<LoginResponse> {
		const response: AxiosResponse<LoginResponse> = await axiosInstance.post(
			'/login',
			{
				email,
				password,
			},
		);
		return response.data;
	}

	static async checkCode(code: string): Promise<LoginResponse> {
		const response: AxiosResponse<LoginResponse> = await axiosInstance.post(
			'/login-with-code',
			{ code },
		);
		return response.data;
	}
}
