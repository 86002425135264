import axiosInstance from '../axiosInstance';
import BaseService from './baseService';
import { AxiosResponse } from 'axios';

class UsersService extends BaseService<UserInterface> {
	constructor() {
		super('user', 'users');
	}

	async changePassword(data: {
		userID: number;
		oldPassword: string;
		newPassword: string;
	}): Promise<UserInterface> {
		const response: AxiosResponse<UserInterface> = await axiosInstance.post(
			`${API_BASE_URL}/users/${data.userID}/change-password`,
			data,
		);
		return response.data;
	}

	async reportUser(data: {
		userID: number;
		reportReason: string;
	}): Promise<UserInterface> {
		const response: AxiosResponse<UserInterface> = await axiosInstance.post(
			`${API_BASE_URL}/users/${data.userID}/report`,
			data,
		);
		return response.data;
	}
}

// This type of exporting is intended to make sure service acts as a singleton.
export default new UsersService();
